import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import { WOW } from 'wowjs';

const Footer = () => {
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.querySelectorAll('.footer-row, .footer-divider, .mt-3, .h4, .social-links a, .list-unstyled li').forEach((el, index) => {
              setTimeout(() => {
                el.classList.add('animate');
              }, index * 200);
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);
  useEffect(() => {
    const wow = new WOW({ live: false });
    wow.init();
  }, []);

  // Scroll to top on link click
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for a smooth scrolling effect
    });
  };

  return (
    <footer className="footer-area" ref={footerRef}>
      <div className="container">
        <div className="footer-row row">
          <div className="col-md-4 mb-4 mb-md-0 wow fadeInLeft "data-wow-duration="2s" data-wow-delay=".2s">
            <Link to="/" className="footer-logo"  onClick={handleScrollToTop}>
              <img src="https://i.postimg.cc/76cjkHtB/2.png" alt="Streamline TV" width="120" />
            </Link>
            <p className="mt-3">Streamline TV - Your #1 Entertainment Source</p>
          </div>
          <div className="col-md-2 col-sm-6 mb-4 mb-md-0">
            <h4>Company</h4>
            <ul className="list-unstyled">
              <li><Link to="/home" onClick={handleScrollToTop}>Home</Link></li>
              <li><Link to="/contact-us" onClick={handleScrollToTop}>Contact Us</Link></li>
              <li><Link to="/pricing" onClick={handleScrollToTop}>Pricing</Link></li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-6 mb-4 mb-md-0">
            <h4>Support</h4>
            <ul className="list-unstyled">
              <li><Link to="/faq" onClick={handleScrollToTop}>FAQ</Link></li>
              <li><Link to="/terms-and-conditions" onClick={handleScrollToTop}>Terms & Conditions</Link></li>
              <li><Link to="/setup" onClick={handleScrollToTop}>Setup</Link></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h4>Follow Us</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61565957574684" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a href="whatsapp://send?phone=+27671473686&text=Hello, Stream Line TV I would like to test for TV for free" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
        
        <div className="footer-divider wow fadeInUp "data-wow-duration="2s" data-wow-delay=".2s"></div>
        
        <div className="footer-row row mt-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
          <div className="col-12 text-center">
            <p className="mt-3" style={{ display: 'inline-block' }}>
              &copy; {new Date().getFullYear()} Streamline TV. All rights reserved for <a className="streamline-text" href="https://streamlinetv4k.com" target="_blank" rel="noopener noreferrer">Streamline TV</a>
            </p>

            <span style={{ display: 'inline-block', marginLeft: '10px' }}>|</span>
                <p className="mt-3" style={{ display: 'inline-block', marginLeft: '10px' }}>
                 <a className="streamline-text" href="./terms-and-conditions" target="_blank" rel="noopener noreferrer">T&Cs</a>
            </p>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
