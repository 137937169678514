import React, { useState } from 'react';
import './WhatsappButton.css';

const WhatsappButton = () => {
  const [isClicked, setIsClicked] = useState(false);
  const phoneNumber = '27671473686';
  const message = encodeURIComponent('Hello, Stream Line TV I would like to try a free trial');

  const handleClick = () => {
    setIsClicked(true);
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
    
    // Reset the clicked state after a short delay
    setTimeout(() => setIsClicked(false), 2000);
  };

  return (
    <div id="whatsapp-button">
      <button 
        className={`whatsapp-btn ${isClicked ? 'clicked' : ''}`} 
        onClick={handleClick}
      >
        <i className="fab fa-whatsapp fa-1x whatsapp-icon"></i>
      </button>
    </div>
  );
};

export default WhatsappButton;
