import React, { useState } from "react";
//import axios from "axios";
import PageHeader from './PageHeader';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const [formStatus, setFormStatus] = useState({
    success: false,
    error: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //const response = await axios.post("contact_process.php", formData);
      setFormStatus({ success: true, error: false });
    } catch (error) {
      setFormStatus({ success: false, error: true });
    }
  };

  return (
    <main>
      {/* Hero Start */}
      <PageHeader title="CONTACT US"  backgroundImage="https://i.postimg.cc/zGMqkXbb/something3.png"/>
      {/* Hero End */}
      
      {/* Contact Area Start */}
      <section className="contact-section" style={{ backgroundColor: 'white' }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form
                className="form-contact contact_form"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        cols="30"
                        rows="9"
                        placeholder="Enter Message"
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name"
                        type="text"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="subject"
                        type="text"
                        placeholder="Enter Subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <button type="submit" className="button button-contactForm boxed-btn">
                    Send
                  </button>
                </div>
              </form>
              {formStatus.success && (
                <div id="form-message-success">Your message was sent, thank you!</div>
              )}
              {formStatus.error && (
                <div id="form-message-error">Something went wrong, please try again.</div>
              )}
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-home"></i>
                </span>
                <div className="media-body">
                  <h3>Gauteng, South Africa.</h3>
                  <p>Woodmead, 2191</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-tablet"></i>
                </span>
                <div className="media-body">
                  <h3>
                    <a href="tel:+27671473686">+27671473686</a>
                  </h3>
                  <p>24/7</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-themify-favicon"></i>
                </span>
                <div className="media-body">
                  <h3>
                    <a href="whatsapp://send?phone=+27671473686&text=Hello, Hello, Stream Line TV I would like to try your TV for free..">
                      Whatsapp
                    </a>
                  </h3>
                  <p>Live Chat !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 
    </main>
  );
};

export default Contact;
