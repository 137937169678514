import React from 'react';
import './assets/css/style.css';
import Setup from './setup';
import OurOffers from './our-offers';
import Pricing from './pricing';
import App from './App';
import './gallery.css';
import './assets/css/aminations.css';
import { WOW } from 'wowjs';
import { useEffect } from 'react';
import heroImage from './assets/img/hero/hero-11.png';

const Home = ({ showSetupBanner = true }) => {
  const galleryItems = [
    {
      title: "Watch Now Apple TV",
      image: "https://i.postimg.cc/zBkBpYYs/appplee.png",
      whatsappMessage: "Hello, Hello, Stream Line TV I would like to Watch Apple TV for free",
    },
    {
      title: "Watch Now Netflix",
      image: "https://i.postimg.cc/xTZd6PB1/rsz-1neflix.jpg",
      whatsappMessage: "Hello, Hello, Stream Line TV I would like to Watch Netflix for free",
    },
    {
      title: "Watch Now Disney+",
      image: "https://i.postimg.cc/Ss4RWN3L/DISME.jpg",
      whatsappMessage: "Hello, Hello, Stream Line TV I would like to Watch Disney+ for free",
    },
  ];
  useEffect(() => {
    const wow = new WOW({ live: false });
    wow.init();
  }, []);

  return (
    <main>
      <div className="slider-area position-relative" style={{backgroundImage: `url(${heroImage})`}}>
        <div className="slider-active">
          <div className="single-slider slider-height d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-9 col-md-10">
                  <div className="hero__caption">
                    <span className="fadeInLeft" data-delay="0.1s">
                      Unlimited Movies, Series & Live TV
                    </span>
                    <h1 className="fadeInLeft" data-delay="0.4s">
                      STREAMLINE tv
                    </h1>
                    <p>
                      With our TV subscription, you can access over 22,000 live TV channels and
                      more than 120k movies and TV shows (VOD) directly on your TV, TV box,
                      smartphone, or tablet. 4k Display.
                    </p>
                    <a href="./pricing" className="border-btn hero-btn2 red-hover slide-in-left" data-delay="0.8s">
                      VIEW PLANS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Slider Area End */}
      <OurOffers showHeader={false} showSpiderman={false}/>
      <div className="gallery-area section-padding30 text-center mb-55 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
        <div className="container">
          <div className="row">
            {galleryItems.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 mb-30" key={index}>
                <div className="gallery-item">
                  <img src={item.image} alt={item.title} className="img-fluid" />
                  <div className="gallery-item-overlay">
                    <div className="overlay-content">
                      <h3>{item.title}</h3>
                      <a
                        href={`whatsapp://send?phone=+27671473686&text=${encodeURIComponent(
                          item.whatsappMessage
                        )}`}
                        className="gallery-icon"
                      >
                        <i className="ti-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <section className="services-area">
     <div className="container myhover">
      <div className="row justify-content-between">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
          <div className="single-services mb-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
            <div className="icon-circle">
            <a href="whatsapp://send?phone=+27671473686&text=Hello, Hello, Stream Line TV I would like to try yout TV for free.">
                <i className="fab fa-whatsapp fa-3x"></i>
             </a>
            </div>
            <div className="features-caption">
              <h3 ><a href="whatsapp://send?phone=+27671473686&text=Hello, Hello, Stream Line TV I would like to try yout TV for free.">WhatsApp</a></h3>
              <p><a href="whatsapp://send?phone=+27671473686&text=Hello, Hello, Stream Line TV I would like to try yout TV for free.">+27 67 147 3686</a></p>
            </div>
          </div>
        </div>
        {/* Contact details */}
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div className="single-services mb-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
            <div className="icon-circle">
              <a href="tel:+27671473686">
              <i className="fas fa-phone-alt fa-2x"></i>
              </a>
            </div>
            <div className="features-caption contact-color">
              <h3><a href="tel:+27671473686">Phone</a></h3>
              <p><a href="tel:+27671473686">+27 67 147 3686</a></p>
              <p><a href="tel:+27671473686">+27 67 147 3686</a></p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
          <div className="single-services mb-40 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
            <div className="icon-circle">
              <a href="mailto:streamlinetv02@gmail.com">
              <i className="fas fa-envelope fa-2x"></i>
              </a>
            </div>
            <div className="features-caption contact-color">
            <h3><a href="mailto:streamlinetv02@gmail.com">Email</a></h3>
              <p><a href="mailto:streamlinetv02@gmail.com">streamlinetv02@gmail.com</a></p>
              <p><a href="mailto:info@streamlinetv4k.com">info@streamlinetv4k.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="about-area2 fix pb-padding pt-50 pb-80">
      <div className="support-wrapper align-items-center">
        <div className="right-content2">
          {/* Image */}
          <div
            className="right-img wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <img
              src="https://i.postimg.cc/rFsmGHnH/hero-2.png"
              alt="Why Us"
            />
          </div>
        </div>
        <div className="left-content2">
          {/* Section Title */}
          <div
            className="section-tittle2 mb-20 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".3s"
          >
            <div className="front-text">
              <h2>Why Us?</h2>
              <p>
                We provide top-notch live coverage of all your favorite sports:
                Football, Golf, Rugby, Cricket, F1, Boxing, NFL, NBA. Get the
                latest sports news, transfers, live scores, TV shows, and guides
                from all Sports TV channels in one place.
              </p>
              <p className="mb-40">
                We offer affordable prices. 24/7 Support. Free Trial, only pay
                after service.
              </p>
              <a href="/pricing" className="border-btn hero-btn2 red-hover">
                View Plans
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
      <Pricing showHeader={false} />
      <Setup showHeader={false} showSetupBanner={showSetupBanner} />
    </main>
  );
};

export default Home;